<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <card>
          <template v-slot:headerTitle>
            <div class="d-flex flex-column align-items-start">
              <h4 class="card-title"><b>ONBOARDING</b></h4>
            </div>
          </template>
          <div class="px-5 py-3">
            <div class="row" style="gap: 1rem">
              <div class="col-12 col-md-10">
                <!-- linar-style cursor glow -->
                <CardTable :data="dataCards" />
              </div>
              <!-- btn new onboarding -->
              <div class="col-12 col-md">
                <BtnAddCardTable text="Nuevo Onboarding" url="Onboarding_agregar" />
              </div>
            </div>
          </div>
          <div class="table-responsive px-4 mt-3">
            <table id="datatable_onboarding" class="table table-bordered table-responsive-md table-striped text-center">
              <thead>
                <tr>
                  <th>Trabajador</th>
                  <th>Cargo</th>
                  <th>Fecha inicio</th>
                  <th>Fecha fin</th>
                  <th>Descripcion</th>
                  <th>Detalles</th>
                  <th>Estado Onboarding</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in listaItems" :key="item.id">
                  <td>
                    {{ item.persona.nombres + " " + item.persona.apellidos }}
                  </td>
                  <td>{{ item.personaUbicacion.nombre }}</td>
                  <td>{{ item.fechaInicio | formatDate }}</td>
                  <td>{{ item.fechaFin | formatDate }}</td>
                  <td>{{ item.descripcion }}</td>
                  <td>
                    <div class="d-flex justify-content-center">

                      <vs-button danger icon v-b-modal.detailsCap
                        @click="filterData(listaItems, item.id, item.persona.nombres, item.persona.apellidos, item.fechaInicio, item.fechaFin, item.descripcion, item.personaUbicacion.nombre)">
                        <i class="fas fa-eye"></i></vs-button>
                    </div>
                  </td>
                  <td>
                    <h4>
                      <b-badge :variant="`${item.estado ? 'success' : 'warning'}`">
                        <i :class="`fas ${item.estado ? 'fa-check' : 'fa-clock'
                          }`"></i>&nbsp;
                        {{ item.estado ? "Terminado" : "Pendiente" }}
                      </b-badge>
                    </h4>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- modal ver mas datalles -->



          <b-modal id="detailsCap" size="xl" hide-footer hide-nav>
            <div>
              <b-card v-if="listaActividades.length">
                <div class="row flex-column-reverse flex-lg-row">
                  <!-- data info onboarding -->
                  <div class="col-12 col-lg">
                    <div class="d-flex align-items-center">
                      <div style="
                          background-color: #df122e;
                          width: 7px;
                          border-radius: 0 20px 0 20px;
                          height: 30px;
                        "></div>
                      <span v-b-tooltip="{
                        title: `${listaActividades[0].nombresPersona}` + ` ` + `${listaActividades[0].apellidosPersona}`,
                        placement: 'bottom',
                        variant: 'primary',
                      }" style="
                          font-size: 1.5rem;
                          font-weight: bolder;
                          margin-left: 5px;
                          max-width: 100%;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        ">
                        ONBOARDING DE : {{ listaActividades[0].nombresPersona + " " +
                          listaActividades[0].apellidosPersona }}
                      </span>

                    </div>
                    <!-- access info -->
                    <div class="d-flex align-items-center flex-wrap mt-3" style="gap: 1.5rem">

                      <!-- fecha inicio -->
                      <div class="d-flex align-items-center">
                        <div class="d-flex justify-content-center align-items-center mr-2">
                          <i class="far fa-calendar-check" style="font-size: 1.5rem"></i>
                        </div>
                        <div class="d-flex flex-column">
                          <small class="font-weight-bold text-white-50">Fecha inicio</small>
                          <span style="font-size: 1.1rem" class="font-weight-bold">
                            {{ listaActividades[0].fechaInicioOnboarding | formatDate }}</span>
                        </div>
                      </div>
                      <!-- divisor -->
                      <div style="
                          width: 1px;
                          height: 20px;
                          background-color: #102e5f;
                        ">
                      </div>
                      <!-- fecha fin -->
                      <div class="d-flex align-items-center">
                        <div class="d-flex justify-content-center align-items-center mr-2">
                          <i class="far fa-calendar-check" style="font-size: 1.5rem"></i>
                        </div>
                        <div class="d-flex flex-column">
                          <small class="font-weight-bold text-white-50">Fecha fin</small>
                          <span style="font-size: 1.1rem" class="font-weight-bold">
                            {{ listaActividades[0].fechaFinOnboarding | formatDate }}</span>
                        </div>
                      </div>
                    </div>
                    <!-- ver mas detalles -->
                    <div class="mt-3">
                      <b-button v-b-toggle.viewDetails size="xl" style="border-radius: 0 25px 0 25px" block>
                        Más detalles
                        <i class="fas fa-chevron-down ml-1"></i></b-button>
                      <b-collapse visible id="viewDetails">
                        <!-- mas info -->
                        <div class="mt-2 d-flex flex-wrap" style="gap: 1.5rem">
                          <!-- nombre trabajador -->
                          <div class=" col-5">
                            <span class="h4" style="
                                padding-left: 5px;
                                font-weight: bold;
                              ">NOMBRE EVALUADO</span>
                            <div class="d-flex align-items-center">
                              <div style="
                                  width: 7px;
                                  height: 7px;
                                  border-radius: 50%;
                                  background-color: #df122e;
                                "></div>
                              <span class="pl-1" v-b-tooltip="{
                                title: `Nombre evaluado`,
                                placement: 'top',
                                variant: 'primary',
                              }">
                                {{ listaActividades[0].nombresPersona + " " +
                                  listaActividades[0].apellidosPersona }}
                              </span>
                            </div>
                          </div>
                          <!-- cargo trabajador -->
                          <div class=" col-5 ">
                            <span class="h4" style="
                                font-weight: bold;
                              ">CARGO EVALUADO</span>
                            <div class="d-flex align-items-center">
                              <div style="
                                  width: 7px;
                                  height: 7px;
                                  border-radius: 50%;
                                  background-color: #df122e;
                                "></div>
                              <span v-b-tooltip="{
                                title: `EMPRESA QUE CAPACITA`,
                                placement: 'top',
                                variant: 'primary',
                              }" class="pl-1">
                                {{ listaActividades[0].cargoPersona }}
                              </span>
                            </div>
                          </div>
                          <!--responsable -->
                          <div class=" col-6">
                            <span class="h4" style="
                                font-weight: bold;
                              ">ONBOARDING RESPONSABLE
                            </span>
                            <div class="d-flex align-items-center">
                              <div style="
                                  width: 7px;
                                  height: 7px;
                                  border-radius: 50%;
                                  background-color: #df122e;
                                "></div>
                              <span v-b-tooltip="{
                                title: `Nombre responsable`,
                                placement: 'top',
                                variant: 'primary',
                              }" class="pl-1" style="
                                  white-space: nowrap;
                                  overflow: hidden;
                                  text-overflow: ellipsis;
                                ">
                                {{ listaActividades[0].usuarioResponsable.nombres + " " +
                                  listaActividades[0].usuarioResponsable.apellidos }}
                              </span>
                            </div>
                          </div>
                          <!--DESCRIPCIÓN ONBOARDING -->
                          <div class=" col-12">
                            <span class="h4" style="
                                
                                font-weight: bold;
                              ">DESCRIPCIÓN ONBOARDING
                            </span>
                            <div class="d-flex align-items-center">
                              {{ listaActividades[0].descripcionOnboarding }}
                            </div>
                          </div>
                        </div>
                        <!-- lista actividades -->
                        <div class="d-flex flex-column align-items-start  pt-4">
                          <span class="h3 font-weight-bold mb-2">Actividades</span>
                          <template v-if="listaActividades.length">
                            <div class="mb-2 col-12 " v-for="(item, index) in listaActividades" :key="item.id"
                              style="padding-left: 0px;">
                              <b-button block class="d-flex justify-content-between align-items-center text-uppercase"
                                v-b-toggle="`collapsar-${index}`" variant="primary">
                                <span class="text-white"><i :class="`ml-1 mr-2 fas ${item.actividadProgreso.porcentajeResp == 100
                                  ? 'fa-check text-success'
                                  : 'fa-clock text-warning'
                                  }`"></i>{{ index + 1 }}. {{ item.nombre }}</span>
                                <i class="fas fa-chevron-down"></i></b-button>
                              <b-collapse :id="`collapsar-${index}`" class="mt-2">
                                <b-card class="position-relative">
                                  <small class="position-absolute text-success" style="right: 5px; top: 5px">último
                                    progreso:
                                    {{
                                      item.actividadProgreso.fechaCreacion | timeSince
                                    }}</small>
                                  <p class="card-text font-italic">
                                    <b>Descripcion: </b>{{ item.descripcion }}
                                  </p>
                                  <p class="card-text font-italic">
                                    <b>Avance actividad: </b>{{ item.actividadProgreso.porcentajeAut }}%
                                  </p>
                                  <p class="card-text font-italic">
                                    <b>Comentario trabajador: </b>{{ item.actividadProgreso.comentarioAut }}
                                  </p>
                                  <hr />
                                  <p class="card-text font-italic">
                                    <b class="text-primary">Mi comentario: </b>{{
                                      item.actividadProgreso.comentarioResp }}
                                  </p>
                                  <!-- actions admin -->
                                  <div class="d-flex flex-column" style="gap: 20px">
                                    <!-- calificar actividad -->
                                    <b-button v-b-toggle="`collapsar-${index}-inner`">
                                      Evaluar actividad <i class="fas fa-star"></i>
                                    </b-button>
                                    <b-collapse :id="`collapsar-${index}-inner`" class="mt-2">
                                      <b-card>
                                        <form @submit.prevent="
                                          sendActividadProgresoResponsable(item, item.id)
                                          ">
                                          <div class="d-flex flex-column justify-content-between" style="gap: 20px">
                                            <b-form-textarea class="w-100" v-model="item.actividadProgreso.newComentarioResp
                                              " placeholder="Escriba un comentario..." minlength="10" maxlength="255"
                                              required></b-form-textarea>
                                            <div class="d-flex justify-content-center align-items-center">
                                              <input class="mr-1 w-100" type="range" id="porcentaje" v-model="item.actividadProgreso.porcentajeResp
                                                " @input="item.changeInput = true" min="0" max="100" step="0" />
                                              <span style="font-size: 30px" :class="`px-2 ${item.actividadProgreso.porcentajeResp < 50
                                                ? 'text-danger'
                                                : item.actividadProgreso.porcentajeResp <
                                                  80
                                                  ? 'text-warning'
                                                  : 'text-primary'
                                                }`"><small>{{
                                                  item.actividadProgreso.porcentajeResp
                                                }}%</small></span>
                                            </div>
                                          </div>
                                          <div class="text-center mt-3">
                                            <b-button block type="submit" variant="outline-light"
                                              :disabled="!item.changeInput">
                                              <i class="fas fa-thumbs-up"></i> Evaluar
                                            </b-button>
                                          </div>
                                        </form>
                                      </b-card>
                                    </b-collapse>
                                  </div>
                                </b-card>
                              </b-collapse>
                            </div>
                          </template>
                          <p v-else>cargando actividades...</p>
                        </div>
                      </b-collapse>
                    </div>


                  </div>
                  <!-- grafic progress confirmacion asistentes  -->
                  <div class="col-12 col-lg">
                    <b-card class="bg-card-r bg-dark">
                      <div class="d-flex flex-column justify-content-center align-items-center" style="height: 100%">
                        <span class=" h3 font-weight-bold mt-3 mb-1 text-uppercase text-center">
                          Actividades finalizadas
                        </span>
                        <!-- component ProgressChart -->
                        <div class="d-flex flex-column justify-content-center align-items-center"
                          v-if="listaActividades.length">
                          <ProgressChart titleCheck="Completadas"
                            :confirmed="listaActividades.filter(item => item.actividadProgreso.porcentajeResp==100).length"
                            :total="listaActividades.length" />


                        </div>
                        <!-- primary icons -->
                        <div class="d-flex flex-row justify-content-center mt-4" style="gap: 2rem">
                          <!-- comentarios -->
                          <div class="d-flex flex-row align-items-center">
                            <div class="d-flex flex-column justify-content-center align-items-center mx-3">
                              <div v-b-tooltip="{
                                title: `Comentarios Responsable`,
                                placement: 'top',
                                variant: 'primary',
                              }" class=" d-flex justify-content-center rounded align-items-center bg-info" style="
                                width: 48px;
                                height: 48px;
                                box-shadow: 0px -1px 33px -8px #4788ff;
                              ">
                                <i class="fas fa-comments fa-fade" style="font-size: 1.2rem"></i>
                              </div>
                              <div class="d-flex flex-column justify-content-center align-items-center">
                                <span style="font-size: 1.8rem; font-weight: bold">
                                  {{
                                    listaActividades.filter(item => item.actividadProgreso.comentarioResp).length
                                  }}
                                </span>


                                <p class="text-white-50">Responsable</p>
                              </div>
                            </div>
                            <div class="d-flex flex-column justify-content-center align-items-center mx-3">
                              <div v-b-tooltip="{
                                title: `Comentarios Trabajador`,
                                placement: 'top',
                                variant: 'primary',
                              }" class="d-flex justify-content-center rounded align-items-center bg-info" style="
                                width: 48px;
                                height: 48px;
                                box-shadow: 0px -1px 33px -8px #4788ff;
                              ">
                                <i class="fas fa-comments fa-fade" style="font-size: 1.2rem"></i>
                              </div>
                              <div class="d-flex flex-column justify-content-center align-items-center">
                                <span style="font-size: 1.8rem; font-weight: bold">
                                  {{
                                    listaActividades.filter(item => item.actividadProgreso.comentarioAut).length
                                  }}
                                </span>
                                <p class="text-white-50">Trabajador</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
              </b-card>
            </div>
          </b-modal>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapMutations } from "vuex";
import { core } from "../../../config/pluginInit";
import moment_timezone from "moment-timezone";
import CardTable from "../../../components/cardTable/CardTable.vue";
import BtnAddCardTable from "../../../components/btnCardTable/btnAddCardTable.vue";
const Swal = require("sweetalert2");

import moment from 'moment/moment';
import "moment/locale/es"
moment.locale('es')
import ProgressChart from "@/components/charts/ProgressChart.vue";

export default {
  name: "Onboarding_admin",
  data() {
    return {
      listaItems: [],
      listaActividades: [],
      evaluarActividad: 0,
      persona: {},
      actividadProgresoResponsable: {
        onboardingAdminId: null,
        actividadOnboardingId: null,
        porcentajeAut: 0,
        comentarioAut: "",
        porcentajeResp: 0,
        comentarioResp: "",
        fechaCreacion: null,
        fechaCierre: null,
      },
      dataCards: [
        {
          title: "Terminandos",
          value: () => this.listaItems.filter((plan) => plan.estado).length,
          iconName: "calendar-check",
          color: "rgba(22, 162, 117, 1)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Terminado").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Pendientes",
          value: () => this.listaItems.filter((plan) => !plan.estado).length,
          iconName: "clock",
          color: "rgba(254, 114, 28, 1)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => {
            console.log("Activated");
            this.dataTableInstance.search("Pendiente").draw();
          },
          onDeactivate: () => {
            console.log("Deactivate");
            this.dataTableInstance.search("").draw();
          },
        },
        {
          title: "Total actividades",
          value: () =>
            this.listaItems.reduce(
              (totalAct, plan) =>
                totalAct + plan.actividadesId.split(",").length,
              0
            ),
          iconName: "bolt",
          color: "rgba(71, 136, 255, 1)",
          tipo: "circle",
          animated: "bounce",
          onActivate: () => console.log("Activated"),
          onDeactivate: () => console.log("Deactivate"),
        },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    await this.getData();
    core.index();
    if (window.$.fn.DataTable.isDataTable("#datatable_onboarding")) {
      window.$("#datatable_onboarding").DataTable().destroy();
    }
    this.dataTableInstance = window.$("#datatable_onboarding").DataTable({
      language: {
        url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
      },
    });
    this.$isLoading(false);
  },
  methods: {
    ...mapMutations(["setPendientesOnboardings"]),
    updatePendientesOnboardings(newValue) {
      this.setPendientesOnboardings(newValue);
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `${this.persona.rol.nivel == 4
            ? "OnboardingAdmin/"
            : "OnboardingAdmin/ByResponsableId/" + this.persona.id
            }`,
        });
        //console.log("list onboardings...", res);
        if (res.length > 0) {
          // Utilizamos Promise.all para esperar a que todas las promesas se resuelvan
          const updatedItems = await Promise.all(
            res.map(async (onboarding) => {
              let ubicacion = await this.getPersonaUbicacion(
                onboarding.persona.id
              );
              //console.log("-------->cargo", ubicacion);
              return {
                ...onboarding,
                personaUbicacion: ubicacion,
              };
            })
          );
          // Ahora updatedItems es un array de objetos de onboarding que han sido enriquecidos con personaUbicacion
          this.listaItems = updatedItems;
          const pendientesOnboardings = this.listaItems.filter((onboarding) => {
            //console.log("--------------------onboarding", onboarding);
            return onboarding.estado == 0;
          });
          this.updatePendientesOnboardings(pendientesOnboardings.length);
        } else {
          this.updatePendientesOnboardings(0);
          this.listaItems = [];
        }
      } catch (error) {
        this.listaItems = [];
        console.log("err", error);
      }
    },
    async sendActividadProgresoResponsable(item, actividadOnboardingId) {
      //console.log(this.listaActividades);
      try {
        let data = {
          ...this.actividadProgresoResponsable,
          porcentajeResp: item.actividadProgreso.porcentajeResp,
          comentarioResp: item.actividadProgreso.newComentarioResp,
          fechaCreacion: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
          fechaCierre: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
          actividadOnboardingId,
        };
        let res = await this.$store.dispatch("hl_post", {
          path: "ActividadOnboarding/CreateActividadProgresoResponsable/",
          data,
        });
        //console.log(res);
        if (res) {
          this.actividadProgresoResponsable.fechaCreacion = this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss");
          // Actualiza el progreso de la actividad en el cliente
          let actividadIndex = this.listaActividades.findIndex(
            (a) => a.id === actividadOnboardingId
          );
          if (actividadIndex !== -1) {
            this.listaActividades[actividadIndex].actividadProgreso = {
              ...this.listaActividades[actividadIndex].actividadProgreso,
              porcentajeResp: item.actividadProgreso.porcentajeResp,
              comentarioResp: item.actividadProgreso.newComentarioResp,
              fechaCreacion: this.actividadProgresoResponsable.fechaCreacion,
            };
          }
          Swal.fire({
            title: "Listo!",
            text: "Evaluacion registrada correctamente",
            icon: "success",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
            cancelButtonText: "Cancelar",
          }).then(async (result) => {
            if (result.isConfirmed) {
              console.log("confirmado");
              item.actividadProgreso.newComentarioResp = "";
              item.changeInput = false;
              const todasEn100 = this.listaActividades.every(
                (actividad) => actividad.actividadProgreso.porcentajeResp == 100
              );
              // Luego del bucle, actuamos según si todas las actividades estaban en 100 o no
              if (todasEn100) {
                console.log(
                  "Todas las actividades tienen un porcentajeResp de 100"
                );
                this.updateEstadoOnboarding(
                  this.actividadProgresoResponsable.onboardingAdminId,
                  { NuevoEstado: 1 }
                );
                this.listaItems.forEach((onboarding) => {
                  if (
                    onboarding.id ==
                    this.actividadProgresoResponsable.onboardingAdminId
                  )
                    onboarding.estado = 1;
                });
                const pendientesOnboardings = this.listaItems.filter(
                  (onboarding) => {
                    //console.log("--------------------onboarding", onboarding);
                    return onboarding.estado == 0;
                  }
                );
                this.updatePendientesOnboardings(pendientesOnboardings.length);
              } else {
                console.log(
                  "No todas las actividades tienen un porcentajeResp de 100"
                );
                this.updateEstadoOnboarding(
                  this.actividadProgresoResponsable.onboardingAdminId,
                  { NuevoEstado: 0 }
                );
                this.listaItems.forEach((onboarding) => {
                  if (
                    onboarding.id ==
                    this.actividadProgresoResponsable.onboardingAdminId
                  )
                    onboarding.estado = 0;
                });
                const pendientesOnboardings = this.listaItems.filter(
                  (onboarding) => {
                    //console.log("--------------------onboarding", onboarding);
                    return onboarding.estado == 0;
                  }
                );
                this.updatePendientesOnboardings(pendientesOnboardings.length);
              }
            }
          });
        }
      } catch (error) {
        console.log("err", error);
      }
    },
    async getActividadById(actividadId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "ActividadOnboarding/GetActividad/" + actividadId,
        });
        //console.log("actividadById...", res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async getPersonaUbicacion(personaId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "PersonaUbicacion/GetByPersona/" + personaId,
        });
        //console.log("personaUbicacion...", res);
        this.personaUbicacion = res;
        return res.cargo;
      } catch (error) {
        this.personaUbicacion = null;
        console.log("err", error);
      }
    },
    async getActividadByOnboardingAdminIdAndActividadOnboardingId(
      onboardingAdminId,
      actividadOnboardingId
    ) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path:
            "ActividadProgresoOnboarding/GetActividadByOnboardingIdAndActividadOnboardingId/" +
            onboardingAdminId +
            "/" +
            actividadOnboardingId,
        });
        //console.log("ActividadProgreso...", res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
    async fetchActividadProgresoForActividades(onboardingAdminId, actividades) {
      let actividadProgresoPromises = actividades.map((actividad) =>
        this.getActividadByOnboardingAdminIdAndActividadOnboardingId(
          onboardingAdminId,
          actividad.id
        )
      );
      return await Promise.all(actividadProgresoPromises);
    },
    async fetchAllDataParallel(data) {
      let dataPromises = data.map((id) => this.getActividadById(id));
      return await Promise.all(dataPromises);
    },
    async updateEstadoOnboarding(onboardingAdminId, data) {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "OnboardingAdmin/UpdateEstado/" + onboardingAdminId,
          data,
        });
        //console.log(res);
        return res;
      } catch (error) {
        console.log("err", error);
      }
    },
    filterData(data, id, nombres, apellidos, fechaInicio, fechaFin, descripcion, cargoPersona) {
      this.actividadProgresoResponsable.onboardingAdminId = id;
      let dataFilter = data.filter((item) => item.id === id);
      let actividades = dataFilter[0].actividadesId.split(",");
      //console.log(actividades);
      this.fetchAllDataParallel(actividades).then(async (result) => {
        // Aquí obtenemos actividadProgreso para cada actividad en result
        let actividadProgresoResults =
          await this.fetchActividadProgresoForActividades(id, result);
        for (let i = 0; i < result.length; i++) {
          result[i].actividadProgreso = actividadProgresoResults[i];
          result[i].actividadProgreso.changeInput = false;
          result[i].actividadProgreso.newComentarioResp = "";

          // Asigna nombres y apellidos a cada elemento en result
          result[i].nombresPersona = nombres;
          result[i].apellidosPersona = apellidos;
          result[i].fechaInicioOnboarding = fechaInicio;
          result[i].fechaFinOnboarding = fechaFin;
          result[i].descripcionOnboarding = descripcion;
          result[i].cargoPersona = cargoPersona;
        }
        this.listaActividades = result;
        //console.log(result);
      });
    },


  },
  filters: {

    formatDate(date) {

      return date ? moment(date).locale("es").format('D MMM YYYY') : "No hay fecha registrada";
    },
    timeSince(dateString, timezone = "America/Bogota") {
      const dateInBogota = moment_timezone.tz(dateString, timezone);
      return dateInBogota.format("YYYY-MM-DD hh:mm A");
    },
  },
  components: { CardTable, BtnAddCardTable, ProgressChart },
};
</script>

<style></style>
